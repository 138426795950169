@import "./variables";
@import './mixins.scss';

.privacy {
  background: white;
  border-radius: 16px;
  padding: 33px;
  position: relative;
  
  &--curtain {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.27);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
  }
  
  &--wrapper {
    z-index: 99999999;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .closes {
    position: absolute;
    right: 25px;
    top: 5px;
    top: 25px;
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    z-index: 1;
    svg {
      color: #4A4A4A;
      font-size: 24px;
    }

    .icon {
      font-size: 19px;
    }

    .font-stroke {
      -webkit-text-stroke: 1px $font-general;
    }
  }

  .privacy--overview {
    .privacy--title {
      font: bold 16px/45px 'Montserrat';
      letter-spacing: 2.4px;
      color: $font-general;
    }

    .text-box {
      max-height: 77px;
      overflow-y: scroll;
      @include custom-scrollbar($brand-primary);

      p {
        text-align: left;
        font: 12px/18px 'Montserrat';
        letter-spacing: 0.9px;
        color: $font-general;
        margin-bottom: 0;
      }
    }

    .policy {
      text-align: left;
      text-decoration: underline;
      font: bold 12px/45px 'Montserrat';
      letter-spacing: 0.9px;
      color: $font-general;
      margin-bottom: 30px;
      margin-bottom: 3px;
      display: block;

      .fa-external-link-alt {
        font-size: 12px;
        margin-left: 5px;
      }
    }

    .collaps-btn {
      width: 100%;
      border: 1px solid #58546F;
      border-radius: 16px;
      padding: 11px 15px;
      position: relative;
      margin-bottom: 10px;

      .fas {
        color: $font-general;
        margin-right: 10px;
      }

      label {
        text-align: left;
        font: 13px/18px 'Montserrat';
        letter-spacing: 0.97px;
        color: $font-general;
        opacity: 1;
        text-transform: capitalize;
        margin-bottom: 0;
      }

      .indicator {
        position: absolute;
        top: 11px;
        right: 15px;
        text-align: right;
        font: 13px/18px 'Montserrat';
        letter-spacing: 0.97px;
        color: $font-general;
        z-index: 10000;
      }

      .note {
        position: absolute;
        right: 54px;
        text-align: right;
        font: 13px/18px 'Montserrat';
        letter-spacing: 0.97px;
        color: #FFFFFF;
        top: 13px;
        &.disabled {
          opacity: .5;
        }
      }

      .trigger {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        cursor: pointer;
      }
    }

    .collaps-content {
      padding: 5px 15px;

      p {
        text-align: left;
        font: 12px/16px 'Montserrat';
        letter-spacing: 0.9px;
        color: $font-general;
        margin-bottom: 10px;

        a {
          font-weight: normal;
          text-decoration: underline;
          color: $font-general;
        }
      }
    }
  }

  // Desktop specific styling
  @media (min-width: $tablet) {
    width: 731px;
  }
  // Mobile specific styling
  @media (max-width: $tablet) {
    padding: 20px;
  }
}