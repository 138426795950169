body{
  width: 100%;
}
.leaflet-container {
  position: relative;
  width: 100%;
  height: 100vh ;
  height: calc(var(--vh, 1vh) * 100);
}
.leaflet-control-attribution.leaflet-control{
  display: none;
}
