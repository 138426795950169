@import "./variables";
@import './mixins.scss';

.cookies {
  background: #fff;
  border-radius: 16px;
  bottom: 70px;
  padding: 6px;
  position: relative;
  width: 53vw;
  z-index: 9999999;
  margin: 0 auto;
  display: block;

    p {
        text-align: left;
        font: 11px/14px 'Montserrat';
        letter-spacing: 0.97px;
        color: #000000;
        margin: 0;
    }
    .btn {
        display: inline-flex;
        margin-top: 0;
        font: 600 12px/20px 'Montserrat';
        color: #2F2D41;
        letter-spacing: 0.9px;
        &--settings{
            text-decoration: underline !important;
            margin-right: 0;
            color: $font-general !important;
            padding: 3px 10px;
        }
        &--accept{
            background-color: #e6e6e6;
            border-radius: 10px;
            padding: 3px 25px;
            &:hover {
                 box-shadow: $box-shadow-hover;
            }
        }
    }

// Mobile specific styling
  @media (max-width: $tablet) {
    padding: 20px;
    bottom: 320px;
    width: 90vw;

    .btn {
        margin-top: 20px;
    }
  }
}

@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

@keyframes slideOutToBottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }


.hide-cookie-container {
    animation: 0.5s ease-out 0s 1 slideOutToBottom;
}
.show-cookie-container {
    animation: 0.5s ease-out 0s 1 slideInFromBottom;
}