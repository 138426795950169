@import './variables.scss';
.btn {
    color: $font-general !important;
    font-family: 'Montserrat';
}
button {
    width: max-content;
    height: 50px;
    color: white;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: bold;
    border-radius: 12px;
    
    &.log-new {
        position: fixed;
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: bold;
        padding-left: 25pt;
        padding-right: 25pt;
        border-radius: 10px;
        width: 170px;
        &--need-help {
            bottom: 100px;
            right: 30px;
        }
        &--can-help {
            bottom: 30px;
            right: 30px;
        }
    }
    &.submit {
        width: 200px;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: bold;
        border-radius: 12px;
        margin-bottom: 40px;
    }
    &.green {
        background-color: $green;
    }
    &.red {
        background-color: $red;
    }

    // Mobile specific styling
    @media (max-width: $tablet) {
    &.log-new {
        right: calc((100vw / 2) - 130px);
        margin-bottom: 40px;
       // bottom: 80px;
        }
    }
}

