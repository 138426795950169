
@import './variables.scss';
@import './mixins.scss';
.sidebar_left {
    
    // General styling
    display: inline-block;
    position: fixed;
    top: 84px;
    // height: 100vh; // minus top height
    // overflow: hidden;
    left: -250px;
    width: 300px;
    transition: .2s all ease-in-out;
    z-index: 1000000000000;
    .error{
      border: 1px solid red;
    }
    &--tab {
      position: relative;
      width: 100%;
      text-align: center;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
      background: white;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      &--trigger {
        padding: 18px;
      }
      span {
        z-index: 1;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        line-height: 24px;
        font-size: 24px;
        color: white;
        font-weight: 600;
        top: 3px;
      }
      i {
        z-index: 1;
      }
      .filter {
        position: absolute;
        right: 15px;
        top: 17px;;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 23px;
        }
        
      }
      &.short {
        background: #3388ff;
      }
    }
    &--panel {
      margin: 0;
      width: $filterPanelWidth;
      position: relative;
      background-color: white;
      padding: 0 $s-s $s-s;
      box-shadow: $box-shadow;
      border-radius: 0 0 0 $border-radius;
      height: calc(100vh - 50px);
      z-index: 2;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .terms-box {
        display: flex;
        margin-bottom: 15px;
        input {
          display: inline-block;
          top: 3px;
          position: relative;
        }
        span {
          display: inline-block;
          margin-left: 5px;
        }
        .privacy-link {
          font-size: 12px;
          color: $font-general !important;
        }
      }

    }
    &--toggle {
      &--container {
        display: flex;
        justify-content: space-between;
        p {
          margin: 0;
          padding: 2px 0;
          font-size: 14px;
          line-height: 2;
        }
        .toggle {
          margin: 0;
        }
      }
    }
    &.open {
      left: 0;
    }
    span {
        margin-left: 15px;
    }
    h1,h2,h3,h4,h5,h6,p,span,label,input,select {
      font-family: 'Montserrat';
      color: #31546D;
      &::placeholder {
        color: #31546D;
      }
    }
    a {
      font-size: 18px;
      color :#6DA86D !important;
      text-decoration: underline;
      cursor: pointer;
      border: 1px solid #6DA86D;
      padding: 6px 15px;
      border-radius: 5px;
      margin-top: 22px;
      display: block;
      width: 125px;

   }
   .log-assist {
    background-color: #6DA86D;
    color: white !important;
    padding: 5px 15px;
    border-radius: 5px;
    text-decoration: none;
  }
  .gps-container {
    display: flex;
    button {
      width: 150px;
      margin-left: 10px;
      margin-top: 0;
      height: 38px;
      color: white;
      background-color: #31546D;
      border-radius: 10px
    };
  }
   .filled-in {
     background-color: #a8b1b93d ;
     padding: 10px 0;
     border-radius: 5px;
     &--container {
       label {
         margin-top: 15px;
       }
       span {
         display: inline-block;
       }
     }
   }
   .contacts {
     margin-bottom: 15px;
   }
   .empty {
    background-color: #ea5a2e69;
    padding: 10px 0;
    border-radius: 5px;
   }
   .tag {
     border-radius: $border-radius;
     padding: 10px 28px;
     color: white;
     &--need {
      background-color: #EA5A2E;
     }
     &--resource {
      background-color: #6DA86D;
     }
   }
   ol {
     margin: 0;
     li {
       p {
         margin: 0;
       }
     }
   }
    // Desktop specific styling
    @media (min-width: $tablet) {
    }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
        width: $totalWidthMobile;
        left: -$panelWidthMobile !important;
        &.open {
            left: 0 !important;
 
          }
          &--panel {
            width: $panelWidthMobile;
            height: calc((var(--vh, 1vh) * 100) - 50px);
          }
      }
    }
    
