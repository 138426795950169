.css {
    &-2b097c-container {
        width: 100%;
    }
    &-yk16xz-control {
        border-radius: 10px !important;
        margin-bottom: 15px;
    }
    &-1wa3eu0-placeholder {
        font-family: 'Montserrat';
    }
}