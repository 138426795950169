@import './variables.scss';

select,
.text-field,
textarea {
    font-family: 'Montserrat';
    font-size: 12px ;
    margin-bottom: 0.2rem;
    position: relative;
    width: 100%;
    border: $border-default;
    border-radius: 10px;
    float: unset;
    padding: 10px;
    // font: $h3;
    color: $brand-primary;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 15px;


    &::placeholder {

        color: $brand-primary;
    }
    option {
        background-color: white !important;
        color:#31546D !important;
    }

}
select {
    background: url('../img/down-arrow.svg') no-repeat right #ddd ;
    background-position-y: 12px;
    background-position-x: 96%;
    background-size: 22px;
    border: none;
}
.select {
    
    &-empty {
        background-color: #31546D;
        color: white !important;
        font-weight: bold;

    }
    &-resource {
        background-color: $green;
        color: white !important;
        font-weight: bold;
    }

    &-need {
        background-color: $red;
        color: white !important;
        font-weight: bold;
    }

}
